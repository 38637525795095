<!DOCTYPE html>
<html lang="es">

<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Web del Profesor Andrés Martínez Lorca</title>
  <!-- <link rel="canonical" href="https://www.andresmlorca.com/"> -->
</head>

<div class="container">
  <body>
    <section>
      <img src="./../../assets/img/Alcazaba_de_Málaga_desde_el_puerto_edited.webp" class="img-fluid" alt="...">
    </section>
    <hr>
    <section>
      <h2><b> {{'app.inicio2' | translate}}</b></h2>

      
      <div class="alert alert-secondary card mb-3" >
        <h1>Prólogo al libro "La filosofía en Al Ándalus", Córdoba, Editorial Almuzara.</h1>
        <hr>
        <div class="row g-0">
          <div class="col-md-3">
            <br>

            <a href="../#/plantilla/4" style="text-decoration:none; color: black;">
            
              <img src="../../../assets/img/la filosofia.webp"
                class=" card-img" alt="..."
                style="max-width: 540px;"> 
         
        </a>


<!-- 
            <a href="./../../assets/pdf/AF_Filosofía en al-Andalus Extract[7-16].pdf" target="_blank">
              <img src="../../../assets/img/la filosofia.webp" class="card-img" style="max-width: 540px;" alt="...">
            </a> -->
          </div>
          <div class="col-md-8">
            <div class="card-body">
             
              <p>Después de una entusiasta recepción de la filosofía arabo-islámica durante la Edad Media y del encumbramiento de Averroes 
                en el Renacimiento a la altura de Aristóteles, siguieron unos siglos de silencio y olvido en Europa. Los prejuicios ilustrados sustituyeron
                a la lectura de los textos, el rostro de los maestros islámicos se desfiguró hasta convertirlos en una burda caricatura, el árabe 
                pasó de ser considerada lengua culta por excelencia gracias a la cual se recuperó la ciencia griega, a ser ignorada por completo cuando 
                no perseguida.</p>
                <p>Mientras tanto, el imperio árabe que se extendía en el Medievo desde la India y las estepas asiáticas hasta el Océano Atlántico y la
                  Península ibérica, se desmoronó sufriendo la invasión de los mongoles, primero, el dominio turco después y  finalmente el colonialismo
                  europeo que se repartió su inmenso territorio a partir del siglo XIX. No tenía, pues, nada de extraño que el pensamiento islámico que
                  había iluminado Europa durante siglos desapareciera de nuestra historia cultural. Sólo algunos orientalistas, ya en la época contemporánea,
                  se atrevieron a desafiar ese pacto de silencio recuperando viejos manuscritos que yacían arrinconados en las grandes bibliotecas, haciendo 
                  renacer el legado de Averroes e Ibn Gabirol, de Avempace y Maimónides. 
                  Así, por ejemplo, los eruditos Salomon Munk y Ernest Renan. Como consecuencia lógica de este menosprecio sistemático, hasta hace pocos años
                  las historias de la filosofía escritas en las principales lenguas europeas apenas le dedicaban, en el mejor de los casos, unas escuetas
                  líneas que servían de hoja de parra de su manifiesta ignorancia... <a href="./../../assets/pdf/AF_Filosofía en al-Andalus Extract[7-16].pdf" target="_blank">(leer el prólogo completo)</a></p>
              
            </div>
          </div>
          
        </div>
      </div>


      <!-- <div class="alert alert-secondary  mb-3" role="alert">
        <h1>Prólogo al libro "La filosofía en Al Ándalus", Córdoba, Editorial Almuzara.</h1>
        <hr>
        <div>
          <img class="img-fluid" style="float: right;" title="Andrés Martínez Lorca" src="../../../assets/img/la filosofia.webp" alt="Andrés Martínez Lorca" class="border border-light-subtle rounded" width="20%" height="20%" alt="...">
        </div>              
        
            <p>Después de una entusiasta recepción de la filosofía arabo-islámica durante la Edad Media y del encumbramiento de Averroes 
              en el Renacimiento a la altura de Aristóteles, siguieron unos siglos de silencio y olvido en Europa. Los prejuicios ilustrados sustituyeron
              a la lectura de los textos, el rostro de los maestros islámicos se desfiguró hasta convertirlos en una burda caricatura, el árabe 
              pasó de ser considerada lengua culta por excelencia gracias a la cual se recuperó la ciencia griega, a ser ignorada por completo cuando 
              no perseguida.</p>
              <p>Mientras tanto, el imperio árabe que se extendía en el Medievo desde la India y las estepas asiáticas hasta el Océano Atlántico y la
                Península ibérica, se desmoronó sufriendo la invasión de los mongoles, primero, el dominio turco después y  inalmente el colonialismo
                europeo que se repartió su inmenso territorio a partir del siglo XIX. No tenía, pues, nada de extraño que el pensamiento islámico que
                había iluminado Europa durante siglos desapareciera de nuestra historia cultural. Sólo algunos orientalistas, ya en la época contemporánea,
                se atrevieron a desa iar ese pacto de silencio recuperando viejos manuscritos que yacían arrinconados en las grandes bibliotecas, haciendo 
                renacer el legado de Averroes e Ibn Gabirol, de Avempace y Maimónides. 
                Así, por ejemplo, los eruditos Salomon Munk y Ernest Renan. Como consecuencia lógica de este menosprecio sistemático, hasta hace pocos años
                las historias de la filosofía escritas en las principales lenguas europeas apenas le dedicaban, en el mejor de los casos, unas escuetas
                líneas que servían de hoja de parra de su manifiesta ignorancia... <a href="./../../assets/pdf/AF_Filosofía en al-Andalus Extract[7-16].pdf" target="_blank">(leer el prólogo completo)</a></p>
           
          </div> -->
        

      
    </section>

<!--     <section>
      <div class="alert alert-success" role="alert">
        <h1>Prólogo al libro "La filosofía en Al Ándalus</h1>
        <hr>
        <p>Después de una entusiasta recepción de la  filosofía arabo-islámica durante la Edad Media y del encumbramiento de Averroes 
        en el Renacimiento a la altura de Aristóteles, siguieron unos siglos de silencio y olvido en Europa. Los prejuicios ilustrados sustituyeron
        a la lectura de los textos, el rostro de los maestros islámicos se desfiguró hasta convertirlos en una burda caricatura, el árabe 
        pasó de ser considerada lengua culta por excelencia gracias a la cual se recuperó la ciencia griega, a ser ignorada por completo cuando 
        no perseguida.</p>
        <p>Mientras tanto, el imperio árabe... <a href="./../../assets/pdf/AF_Filosofía en al-Andalus Extract[7-16].pdf" target="_blank">(leer articulo completo)</a></p>
      </div>
    </section> -->


    <section> <!-- NOVEDADES -->
      <div class="alert alert-secondary" role="alert">

        <h1>MUY SOPHIA-FILOSOFÍA MEDIEVAL | MUY HISTORIA ED. COLECCIONISTA Nº 36</h1>
        <hr>
        <p  style="text-decoration:none; color: black;">
            
              
          <a href="./../../assets/pdf/MUY SOPHIA [92-105].pdf" target="_blank">
            <img src="../../assets/img/Portada Muy.png" loading="" class="card-img-top" width="50%" height="50%" alt="...">
          </a>
          
        </p>
      </div>
      
      

     <!--  <div class="card-group" style="font-family: Tiempos, 'Playfair Display', serif; font-weight: 400;">
        <div class="card text-dark text-center" style="border: 0ch;">
          <hr>
          
          <p  style="text-align: center; font-size: x-large ; text-decoration:none; color: black;">MUY SOPHIA-FILOSOFÍA MEDIEVAL | MUY HISTORIA ED. COLECCIONISTA Nº 36</p>
          
          <p  style="text-decoration:none; color: black;">
            
              
            <a href="./../../assets/pdf/MUY SOPHIA [92-105].pdf" target="_blank">
              <img src="../../assets/img/Portada Muy.png" loading="" class="card-img-top" alt="...">
            </a>
            
          </p>
          
        </div> 

      </div> -->

      <!-- <div class="abs-center">
        <div class="row row-cols-1 row-cols-md-1 g-4" >
          <div class="col">
            <p  style="text-align: center; font-size: x-large ; text-decoration:none; color: black;">MUY SOPHIA-FILOSOFÍA MEDIEVAL | MUY HISTORIA ED. COLECCIONISTA Nº 36</p>
            <div class="card.jover card text-bg-light">
              <a href="./../../assets/pdf/MUY SOPHIA [92-105].pdf" target="_blank">
                <img src="../../assets/img/Portada Muy.png" loading="lazy" class="card-img-top" alt="...">
              </a>
            </div>
          </div>
        </div>
      </div> -->
<br>



<section>
      <h2><b> {{'app.inicio2' | translate}}</b></h2>

      
      <div class="alert alert-success card mb-3" >
        
        <div class="row g-0">
          <h1>Entre líneas 22 Entrevista al profesor Andrés Martínez Lorca, ADEH TV</h1>
            <hr>
          <div class="col-md-10">
            
            <div class="card-body">
             
              <p>La Asociación de Estudios Humanísticos de Argentina (ADEH) presenta esta entrevista en la que se detallan las diversas etapas de sus investigaciones y los temas que le han interesado al profesor Lorca a lo largo de su vida académica.</p>
              <p><a href="https://www.youtube.com/embed/a3bKKDt9E58?si=aB2zigSapOAHMtHy" target="_blank"> Ver video completo</a></p>
            </div>
          </div>
          <div class="col-md-1">
            
            <!-- <a href="../#/plantilla/4" style="text-decoration:none; color: black;">
            
              <img src="../../../assets/img/la filosofia.webp"
                class=" card-img" alt="..."
                style="max-width: 540px;"> 
         
        </a> -->



            
              <img src="../../../assets/img/26431998.webp" class="card-img"  alt="...">
            
          </div>
          
        </div>
      </div>


      
        

      
    </section>


<!-- <section>
  <div class="alert alert-success" role="alert">
    <h1>Entre líneas 22 Entrevista al profesor Andrés Martínez Lorca, ADEH TV</h1>
    <hr>
    
    <p>La Asociación de Estudios Humanísticos de Argentina (ADEH) presenta esta entrevista en la que se detallan las diversas etapas de sus investigaciones y los temas que le han interesado al profesor Lorca a lo largo de su vida académica. <a href="https://www.youtube.com/embed/a3bKKDt9E58?si=aB2zigSapOAHMtHy" target="_blank"> Ver video completo</a></p>
     
  </div>
</section> -->

      
      <!-- <div class="card-group" style="font-family: Tiempos, 'Playfair Display', serif; font-weight: 400;">
        <div class="card text-dark text-center" style="border: 0ch;">
          <hr>
          
          <p  style="text-align: center; font-size: x-large ; text-decoration:none; color: black;">Entrevista en "Entre líneas" ADEH TV</p>
          
          <div class="video-responsive">


            
          <p  style="text-decoration:none; color: black;">
            
            
                  <iframe src="https://www.youtube.com/embed/a3bKKDt9E58?si=aB2zigSapOAHMtHy" 
                  
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowfullscreen>
                  </iframe>
              
            
          </p>
        </div>
          
        </div> 

      </div> -->


  <hr>


      <div class="card-group" style="font-family: Tiempos, 'Playfair Display', serif; font-weight: 400;">
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color ">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/32" style="text-decoration:none; color: black;">
            
                <img src="./../../assets/img/averroes-substancia.webp" 
                  class=" img-fluid rounded-start" alt="..."
                  width="300px" height="200px"> 
           
          </a>
        </div> 


        <br>
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/0" style="text-decoration:none; color: black;">
           
                <img src="./../../assets/img/FILOSOFÍA-MÍSTICA-Y-RELIGIÓN-EN-EL-ISLAM-MEDIEVAL.webp"
                  class="img-fluid rounded-start" alt="..." 
                  width="300px" height="200px"> 
           
          </a>
        </div>


        <br>
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/1" style="text-decoration:none; color: black;">
            
                <img src="./../../assets/img/edition-2779-236x354.webp" class="img-fluid rounded-start" alt="..."
                  width="300px" height="150px"> 
            
          </a>
        </div> 

        <!-- <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/32" style="text-decoration:none; color: black;">
            <div class="row g-0">
              <div class="col-md-3  card card-color" style="border: none;">
                <img src="./../../assets/img/Averroes substancia.webp" class="img-fluid rounded-start" alt="..."
                
                  width="100%" height="80%"> 

                

              </div>
              <div class="col-md-8">

                <div class="card-body">

                  <h2 class="card-title" style="color:white">Averroess, Sobre la substancia del universo [De substantia
                    orbis] </h2>

                </div>
              </div>
            </div>
          </a>
        </div> 


        <br>
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/0" style="text-decoration:none; color: black;">
            <div class="row g-0">
              <div class="col-md-3  card card-color" style="border: none;">
                <img src="./../../assets/img/FILOSOFÍA-MÍSTICA-Y-RELIGIÓN-EN-EL-ISLAM-MEDIEVAL.webp"
                  class="img-fluid rounded-start" alt="..." width="100%" height="100%">
              </div>
              <div class="col-md-8">

                <div class="card-body">
                  <h2 class="card-title" style="color:white">Filosofía, mística y religión en el islam medieval</h2>
                </div>
              </div>
            </div>
          </a>
        </div>


        <br>
        <div class="card text-dark mb-3 border-primary-subtle text-center card-color">
          <p style="color:white; background-color: black; ">{{'app.inicio14' | translate}}</p>
          <a href="../#/plantilla/1" style="text-decoration:none; color: black;">
            <div class="row g-0">
              <div class="col-md-3 card card-color" style="border: none;">
                <img src="./../../assets/img/edition-2779-236x354.webp" class="img-fluid rounded-start" alt="..."
                  width="100%" height="100%">
              </div>
              <div class="col-md-8">

                <div class="card-body">
                  <h2 class="card-title" style="color:white;">Sobre la melancolía, por la diversidad cultural, contra la
                    guerra.</h2>
                </div>
              </div>
            </div>
          </a>
        </div> -->
      </div>
    </section>
    <hr>

    <section>
      <div class="abs-center">
        <div class="row row-cols-1 row-cols-md-1 g-4" style="max-width: 640px;">
          <div class="col">
            <div class="card.jover card text-bg-light">
              <img src="../../assets/img/1.webp" loading="lazy" class="card-img-top" alt="...">
            </div>
          </div>
          <div>
            <hr>
            <h1 class="card-title" style="background-color: transparent; color: black; text-align: center;">
              {{'app.inicio4' | translate}}</h1>
            <hr>
            <p class="card-text">{{'app.inicio5' | translate}}</p>
            <p class="card-text">{{'app.inicio6' | translate}}</p>
            <p class="card-text">{{'app.inicio7' | translate}}</p>
            <p class="card-text">{{'app.inicio8' | translate}}</p>
            <br>
          </div>
        </div>

      </div>
    </section>


  </body>


</div>

</html>
<app-footer></app-footer>