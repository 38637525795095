<body>


  <div class="container"  style="height: 80vh">
    <mat-toolbar color="primary"  class="fixed-header" >
      <mat-toolbar-row >
        <div class="flexContainer">
          <button class="fuera" mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm color="" style="background-color: #4054b4; color: white;">
            <mat-icon>menu</mat-icon>
          </button>
          
          <span > {{'app.titulo' | translate}}</span>
          <a mat-button routerLink="/inicio" matTooltip="{{'app.icon1' | translate}}" class="peque" style="background-color: #4054b4; color: white;">
            <mat-icon>home</mat-icon>
          </a> 
        </div>
        <span class="example-spacer"></span>

      <mat-form-field class="fuera" style = "width: 70px; font-size: small;" appearance="fill" matTooltip="{{'app.icon2' | translate}}">
          <mat-select value = "0" disableRipple>
            <mat-option style="background-color: #4054b4; color: white;" value = "0" (click)="cambiarIdioma('es')" >ESP</mat-option>
            <mat-option style="background-color: #4054b4; color: white;" value = "1" (click)="cambiarIdioma('en')" >ENG</mat-option>
          </mat-select>
        </mat-form-field>
      
      </mat-toolbar-row>
      <mat-toolbar-row class="peque">
        <span class="example-spacer"></span>
        <div fxShow="true" color="warn" >
          <a routerLink="/publicaciones" class="color ">{{'app.menu1' | translate}}</a> 
          <a routerLink="/alandalus" class="color ">{{'app.menu2' | translate}}</a> 
          <a routerLink="/sobre" class="color ">{{'app.menu3' | translate}}</a> 
          <a routerLink="/masinfo" class="color ">{{'app.menu4' | translate}}</a> 
        </div>
      </mat-toolbar-row> 
      <!-- Barra para movil-->
      <mat-toolbar-row class="nuevotitulo">
        <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm color="">
          <mat-icon>menu</mat-icon>
        </button>

        <a mat-button routerLink="/inicio" matTooltip="{{'app.icon1' | translate}}" class="nuevotitulo">
          <mat-icon>home</mat-icon>
        </a> 

        <mat-form-field style = "width: 70px; font-size: small;" appearance="fill" matTooltip="{{'app.icon2' | translate}}">
          <mat-select value = "0" disableRipple>
            <mat-option value = "0" (click)="cambiarIdioma('es')" >ESP</mat-option>
            <mat-option value = "1" (click)="cambiarIdioma('en')" >ENG</mat-option>
          </mat-select>
        </mat-form-field>
                
      </mat-toolbar-row> 
    </mat-toolbar>
    
    
    <mat-sidenav-container >
      <mat-sidenav #sidenav class="color-sidenav" >
        <mat-nav-list>
          <a (click)="sidenav.close()" routerLink="./../inicio/" style="color: white;" mat-list-item>{{'app.menu8' | translate}}</a>
          <a (click)="sidenav.close()" routerLink="./../publicaciones/" style="color: white;" mat-list-item>{{'app.menu1' | translate}}</a>
          <a (click)="sidenav.close()" routerLink="./../alandalus/" style="color: white;" mat-list-item>{{'app.menu2' | translate}}</a>
          <a (click)="sidenav.close()" routerLink="./../sobre/" style="color: white;" mat-list-item>{{'app.menu3' | translate}}</a>
          <a (click)="sidenav.close()" routerLink="./../masinfo" style="color: white;" mat-list-item>{{'app.menu4' | translate}}</a>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content><router-outlet></router-outlet></mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</body> 