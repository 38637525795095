<body>
   
    <div class="alert alert-secondary" role="alert">
      <h1>{{'app.inicio9' | translate}}</h1>
      <hr>

        <div >
       
          <img style="float: right;" title="Andrés Martínez Lorca" src="../../../assets/img/26431998.webp" alt="Andrés Martínez Lorca" class="border border-light-subtle rounded" width="20%" height="20%" alt="...">
        </div>
        <p>{{'app.inicio10' | translate}}</p>
        <p>{{'app.inicio11' | translate}}</p>
        <p>{{'app.inicio12' | translate}}</p>
        <p>{{'app.inicio13' | translate}}</p>
     
     
    </div>
    
  </body>  


